import React from "react"
import styled, { css } from "styled-components"
import Img from "gatsby-image"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

const Testimonials = props => {
  return (
    <div>
      <CardGrid maxWidth="lg">
        <Typography
          component="h2"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Testimonials
        </Typography>
        <GridContainer>
          {props.testimonials.map(item => (
            <div
              data-sal="zoom-in"
              data-sal-delay="100"
              data-sal-duration="800"
              data-sal-easing="ease-out-cubic"
              key={item.name}
            >
              <Card key={item.name} elevation={3}>
                <CardContent className="card-content">
                  <Typography
                    paragraph
                    variant="body2"
                    component="p"
                    color="textSecondary"
                  >
                    ”{item.comment}”
                  </Typography>
                  <CardGridContainer logoPath={item.logoPath}>
                    <div>
                      {item.logoPath && (
                        <Img
                          fluid={item.logoPath.childImageSharp.fluid}
                          alt={item.company}
                        />
                      )}
                    </div>

                    <div>
                      <Typography variant="h6" component="p">
                        {item.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="p"
                        color="textSecondary"
                      >
                        {item.title}, {item.company}
                      </Typography>
                    </div>
                  </CardGridContainer>
                </CardContent>
              </Card>
            </div>
          ))}
        </GridContainer>
      </CardGrid>
    </div>
  )
}

const CardGrid = styled(Container)`
  padding-top: ${props => props.theme.spacing(8)}px;
  padding-bottom: ${props => props.theme.spacing(8)}px;
  border-top: 1px solid #e0e0e0;

  .card-content {
    flex-grow: 1;
    height: 100%;
  }
`
const CardGridContainer = styled.div`
  ${props =>
    props.logoPath &&
    css`
      display: grid;
      grid-gap: ${props => props.theme.spacing(2)}px;
      grid-template-columns: 80px 1fr;
      justify-content: center;
      align-items: center;
    `}
`

const GridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(3)}px;
  grid-template-columns: 1fr;
  margin-top: ${props => props.theme.spacing(5)}px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default Testimonials
