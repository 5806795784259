import React from "react"
import styled from "styled-components"

import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import SendIcon from "@material-ui/icons/Send"

import Layout from "../components/layout"
import Head from "../components/head"
import Testimonials from "../components/testimonials"
import Services from "../components/services"
import hpHeroSvg from "../assets/hp-hero.svg"

const IndexPage = () => {
  return (
    <Layout>
      <Head title="Home" />
      <HideOnScrollMargin>
        <HeroContent>
          <Container maxWidth="lg">
            <HeroGridContainer>
              <div>
                <div
                  data-sal="slide-right"
                  data-sal-delay="100"
                  data-sal-duration="800"
                  data-sal-easing="ease-out-cubic"
                >
                  <Typography
                    component="h1"
                    variant="h2"
                    align="left"
                    color="textPrimary"
                    gutterBottom
                  >
                    Cut-Through Communications
                  </Typography>

                  <Typography
                    variant="h5"
                    align="left"
                    color="textSecondary"
                    paragraph
                  >
                    for enterprises, small businesses, not-for-profits, B2B, and
                    technology brands.
                  </Typography>
                </div>
                <HeroButton>
                  <Grid container spacing={2} justify="flex-start">
                    <Grid item>
                      <div
                        data-sal="slide-right"
                        data-sal-delay="300"
                        data-sal-duration="800"
                        data-sal-easing="ease-out-cubic"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          href="mailto:mylan@vu-consulting.com"
                          size="large"
                          endIcon={<SendIcon />}
                        >
                          Get in touch
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </HeroButton>
              </div>
              <HeroImageWrap>
                <div
                  data-sal="zoom-in"
                  data-sal-delay="100"
                  data-sal-duration="800"
                  data-sal-easing="ease-out-cubic"
                >
                  <img
                    src={hpHeroSvg}
                    alt="Mylan cut through communications"
                    className="image"
                  />
                </div>
              </HeroImageWrap>
            </HeroGridContainer>
          </Container>
        </HeroContent>
        <Services />
        <Testimonials />
      </HideOnScrollMargin>
    </Layout>
  )
}

const HeroImageWrap = styled.div`
  text-align: center;

  .image {
    width: 100%;
  }
`

const HeroContent = styled.div`
  padding: ${props => props.theme.spacing(7, 0, 4)};

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    padding: ${props => props.theme.spacing(10, 0, 7)};
  }
`

const HeroGridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(6)}px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr;
  }
`

const HideOnScrollMargin = styled.main`
  margin-top: 56px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin-top: 64px;
  }
`

const HeroButton = styled.div`
  margin-top: ${props => props.theme.spacing(5)}px;
`

export default IndexPage
