import React from "react"
import styled from "styled-components"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"

import mediaRelations from "../../assets/conference.svg"
import crisisComm from "../../assets/warning.svg"
import contentDev from "../../assets/content_creator.svg"
import mediaTraining from "../../assets/conference_speaker.svg"
import leadership from "../../assets/gravitas.svg"
import profilling from "../../assets/profile.svg"
import fundraising from "../../assets/fundraising.svg"
import advocacy from "../../assets/advocacy.svg"
import writing from "../../assets/writing.svg"

const WHAT_WE_DO = [
  {
    title: "Media relations",
    subtitle:
      "Proactive and reactive press office management based on strong local relationships",
    media: mediaRelations,
  },
  {
    title: "Crisis communications",
    subtitle:
      "Strategic communications during crises, and forward planning for potential risks ",
    media: crisisComm,
  },
  {
    title: "Content development",
    subtitle:
      "Fast turn-around of high-quality blogs, social media content, corporate bios, website content and more",
    media: contentDev,
  },
  {
    title: "Media training",
    subtitle:
      "Half-day training sessions to ensure spokespeople take control of the interview",
    media: mediaTraining,
  },
  {
    title: "Thought leadership",
    subtitle:
      "Generating cut-through with unique thought leadership messaging and press office activities",
    media: leadership,
  },
  {
    title: "Corporate profiling",
    subtitle:
      "Strategic positioning of industry experts to boost their reputation and industry rapport",
    media: profilling,
  },
  {
    title: "Fundraising",
    subtitle:
      "Targeted grassroots campaigns that engage with audiences and donors to drive impact",
    media: fundraising,
  },
  {
    title: "Advocacy",
    subtitle:
      "Messaging development and training to initiate change, engage government and corporate stakeholders, or raise awareness of marginalised communities and issues",
    media: advocacy,
  },
  {
    title: "Business case writing",
    subtitle:
      "Compelling narrative development, for business cases or a case for support, that help attract investment, funding, or stakeholder buy-in",
    media: writing,
  },
]

const GridContainer = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spacing(4)}px;
  grid-template-columns: 1fr;
  margin-top: ${props => props.theme.spacing(5)}px;

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const CardGridContainer = styled(Container)`
  padding-top: ${props => props.theme.spacing(8)}px;
  padding-bottom: ${props => props.theme.spacing(8)}px;
  border-top: 1px solid #e0e0e0;
`

const CardWrap = styled(Card)`
  height: 100%;

  .card-content {
    flex-grow: 1;
    height: 100%;

    .card-grid-container {
      display: grid;
      grid-gap: ${props => props.theme.spacing(5)}px;
      grid-template-rows: 240px 1fr;
      justify-content: center;

      .media-wrap {
        display: flex;
        align-items: center;

        .media {
          height: 80%;
          width: 80%;
          object-fit: contain;
        }
      }
    }
  }
`

const Services = () => {
  return (
    <div>
      <CardGridContainer maxWidth="lg">
        <Typography
          component="h2"
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Services
        </Typography>
        <GridContainer>
          {WHAT_WE_DO.map(item => (
            <div
              data-sal="slide-right"
              data-sal-delay="100"
              data-sal-duration="800"
              data-sal-easing="ease-out-cubic"
              key={item.title}
            >
              <CardWrap elevation={3}>
                <CardContent className="card-content">
                  <div className="card-grid-container">
                    <div className="media-wrap">
                      <img
                        src={item.media}
                        alt={item.title}
                        className="media"
                      />
                    </div>

                    <div>
                      <Typography gutterBottom variant="h4" component="h3">
                        {item.title}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body1"
                        component="p"
                        color="textSecondary"
                      >
                        {item.subtitle}
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </CardWrap>
            </div>
          ))}
        </GridContainer>
      </CardGridContainer>
    </div>
  )
}

export default Services
